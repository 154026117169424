import uniqid from 'uniqid';
import { projects } from '../portfolio';
import ProjectContainer from './projectcontainer';
import './css/projects.css';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Projects = () => {
    const { ref, inView } = useInView({ triggerOnce: false });

  if (!projects.length) return null;

  return (
    <section
      id="projects"
      className="section projects"
      ref={ref}
    >
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }}
        transition={{ duration: 1.0 }}
      >
        <h2 className="section__title">Projects</h2>
        <br />
        <br />
        <div className="projects__grid">
          {projects.map((project) => (
            <ProjectContainer key={uniqid()} project={project} />
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default Projects;
