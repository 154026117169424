const header = {
    homepage: 'https://surendramedisetti.com',
    title: 'Surendra',
  }
  
  const about = {
    name: 'Surendra Medisetti',
    role: 'Full Stack Developer based in United States',
    description: 'Dedicated to crafting immersive and intuitive web experiences. In this portfolio, I showcase my journey through the digital realm, where pixels meet purpose and creativity meets functionality',
    resume: 'https://surendramedisetti.com/resume/Surendra_Resume.pdf',
    social: {
      linkedin: 'https://linkedin.com',
      github: 'https://github.com',
    },
  
  }

  const softwareSkills = [
    "C",
    "C++",
    "Java",
    "Python",
    "R",
    "Spring Boot",
    "ReactJs",
    "Restful API",
    "MySQL",
    "HTML",
    "CSS",
    "Javascript",
    "TensorFlow",
    "Scikit-Learn",
    //"Android Studio",
    "Keras"
  ]
 


  const projects = [
    {
      name: 'Milliyard Shoppy',
      description:
        'Developed an independent project offering an engaging platform to explore and book Royal Enfield models through a microservices architecture using React, Framer Motion, Spring Boot, Spring Data JPA, Spring Security, Axios, and MySQL',
      stack: ['ReactJS', 'Spring Boot', 'Spring Data JPA', 'Spring Security', 'MySQL', 'Postman'],      
      sourceCode: '',
      livePreview: 'https://milliyard.in',
    },
    {
      name: 'Credit Card Billing System',
      description:
        'Credit card bill system that reduced processing time through optimized algorithms, efficient database management and automated payment reminders, resulting decrease in late payments and increase in on-time payments',
      stack: ['Spring Boot', 'TypeScript', 'ReactJS', 'MySQL', 'Postman'],
      //sourceCode: 'https://github.com',
      //livePreview: 'https://github.com',
    },
     {
      name: 'All in one status saver Android App',
      description:
        'Easily download and save images, videos, and GIFs from various social media statuses with this user-friendly app. Designed in Android Studio, it offers quick access, multi-save options, and a built-in media player for seamless sharing and organization.',
      stack: ['Android Studio', 'Java', 'Flutter'],
      //sourceCode: 'https://github.com',
       livePreview: 'https://play.google.com/store/apps/details?id=com.milliyard.statussaver',
     },
  ]
  
  const skillDesc = {
    description : 'Highly skilled Software Engineer with a Master’s in Data Science and over 2 years of experience in developing scalable, high performance applications using C, C++, Java, Python, Spring Boot, and ReactJS. Proven track record in troubleshooting complex IT issues, optimizing algorithms, and enhancing security measures. Passionate about driving innovation and delivering cutting-edge DPU-based storage solutions'
    //'My passion for frontend development is not only reflected in my extensive experience but also in enthusiasm and dedication I bring to each project'
  }

  
  export { header, about, projects, skillDesc, softwareSkills }