import { useContext } from 'react'
import './App.css';
import { ThemeContext } from './utils/theme'
import TopScroll from './utils/topscroll';
import Header from './components/header';
import Footer from './components/footer';
import Home from './components/home';
import Projects from './components/projects';
import AboutMe from './components/about';
import Contact from './components/contact';
import { motion } from 'framer-motion';


const App = () => {
  const [{ themeName }] = useContext(ThemeContext);


  return (
    <div id='top' className={`${themeName} app`}>
      <Header />
      <main>
       <Home />
       <AboutMe/>
        <Projects />    
        <Contact /> 
      </main>
      <TopScroll />
      <Footer />
    </div>
  )
}

export default App;
