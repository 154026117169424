import React, { useState } from 'react';
import './css/contact.css';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [errors, setErrors] = useState({});
    const { ref, inView } = useInView({ triggerOnce: false });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};

        if (!formData.name.trim()) {
            errors.name = 'Name is required';
        }

        if (!formData.email.trim()) {
            errors.email = 'Email is required';
        } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
            errors.email = 'Invalid email format';
        }

        if (!formData.message.trim()) {
            errors.message = 'Message is required';
        }

        if (Object.keys(errors).length === 0) {
            console.log('Form submitted:', formData);
            setFormData({
                name: '',
                email: '',
                message: ''
            });
        } else {
            setErrors(errors);
        }
    };

    return (
        <div id="contact" className="contact-section" ref={ref}>
            <motion.div
                initial={{ opacity: 0, x: -60 }}
                animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -60 }}
                transition={{ duration: 0.5}}
            >
                <h2 className='section__title'>Contact Me</h2>
                <p>Feel free to get in touch with me!</p>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
                        {errors.name && <span className="error-message">{errors.name}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
                        {errors.email && <span className="error-message">{errors.email}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message:</label>
                        <textarea id="message" name="message" rows="4" value={formData.message} onChange={handleChange}></textarea>
                        {errors.message && <span className="error-message">{errors.message}</span>}
                    </div>
                    <button className='btn btn--outline btnstyle' type="submit">Send</button>
                </form>
            </motion.div>
        </div>
    );
};

export default Contact;
