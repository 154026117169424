import uniqid from 'uniqid'
import GitHubIcon from '@material-ui/icons/GitHub'
import LaunchIcon from '@material-ui/icons/Launch'
import './css/projectcontainer.css'
import { motion } from 'framer-motion';

const ProjectContainer = ({ project }) => (
  <div className='project'>
    <div className='row'>
         <h3 className='project__title'>{project.name}     
    {project.livePreview && (
      <a
        href={project.livePreview}
        aria-label='live preview'
        className='link link--icon'
      >
        <LaunchIcon />
      </a>
    )}
        {/* {project.sourceCode && (
      <a
        href={project.sourceCode}
        aria-label='source code'
        className='link link--icon'
      >
        <GitHubIcon />
      </a>
    )} */}
      </h3>
    </div>
 
    <p className='project__description'>{project.description}</p>
    
    {project.stack && (
      <ul className='project__stack'>
        {project.stack.map((item) => (
          <li key={uniqid()} className='project__stack-item'>
            {item}
          </li>
        ))}
      </ul>
    )}


  </div>
)

export default ProjectContainer