import React from 'react';
import profileImage from './../media/profile.png';
import './css/about.css'
import {skillDesc, about, softwareSkills} from '../portfolio'
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useState, useEffect } from 'react';


const AboutMe = () => {
    
    const { description } = skillDesc;
    
    const { ref, inView } = useInView({ triggerOnce: false });
      const controls = useAnimation();
    
      useEffect(() => {
        if (inView) {
          controls.start({
            opacity: 3,
            x: 1,
            transition: { duration: 1.0 },
          });
        }
      }, [controls, inView]);
    
      return (
        
        <div id="aboutme" className="about-me">
           <motion.div 
              className="skills"
              initial={{ opacity: 0, x: -50 }}
              animate={controls}
           >
          <h2 className="section__title">About Me</h2>
          <br/>
          <div className="profile-section">
            <div className="profile-image">
              <img src={profileImage} alt="Profile" />
            </div>
            <div className="description">
              <p>{description}</p>
    
              <div className="skills-section" ref={ref}>
                <h3>Skills</h3>
                <br />

               
               
             
                <div className="skills-container">
  {softwareSkills.map((skill, i) => {
    return (
      <div key={i} className="skill-item">
        <p>{skill}</p>
      </div>
    );
  })}
</div>
               
{/* 
                <motion.div
                  className="skill"
                  initial={{ opacity: 0, x: -50 }}
                  animate={controls}
                >
                  <p>HTML & CSS</p> &nbsp;
                  <div className="skill-bar">
                    <div className="skill-level" style={{ width: '80%' }}></div>
                  </div>
                </motion.div>
                <motion.div
                  className="skill"
                  initial={{ opacity: 0, x: -50 }}
                  animate={controls}
                >
                  <p>React JS</p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <div className="skill-bar">
                    <div className="skill-level" style={{ width: '90%' }}></div>
                  </div>
                </motion.div>
                <motion.div
                  className="skill"
                  initial={{ opacity: 0, x: -50 }}
                  animate={controls}
                >
                  <p>Javascript</p>&nbsp;&nbsp;&nbsp;
                  <div className="skill-bar">
                    <div className="skill-level" style={{ width: '85%' }}></div>
                  </div>
                </motion.div> */}
              </div>
            </div>
          </div>
          </motion.div>
        </div>
      );
 
};

export default AboutMe;
