import './css/footer.css'
import { motion } from 'framer-motion';

const Footer = () => (
  <footer className='footer'>
    <a
      className=' footer__link'
    >
     <p>&copy; 2024 Surendra. All rights reserved.</p>
    </a>
  </footer>
)

export default Footer